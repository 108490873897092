import merge from 'lodash/merge';

export default (app) => {
  const localizeConfig = app.general.getItem('localizejs');
  const customLocalizeConfig = {
        enabled: true,
        appKey: 'wE6sqScIMShlf',
  };

  app.general.changeItem('localizejs', merge(localizeConfig, customLocalizeConfig));
};
